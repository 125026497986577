import React, { useState } from "react";
import { useVerifyRecaptcha } from "./useVerifyRecaptcha";
import { useSignupForm } from "./useSignupForm";
import SignupForm from "./SignupForm";
import VerifyEmailForm from "./VerifyEmailForm";
import Mark from "./Mark";
import { Footer } from "../layout";
import "../../assests/styles/auth.scss";


function Signup() {
  const { onRecaptchaChange, isRecaptchaVerified } = useVerifyRecaptcha();
  const {
    onFinish,
    setEmail,
    otp,
    setOtp,
    verifyOTP,
    isLoading,
    onFinishFailed,
  } = useSignupForm();
  const [signupStep, setSignupStep] = useState(0);

  const handleSignupSuccess = (email) => {
    setEmail(email);
    setSignupStep(1); // Proceed to OTP verification step
  };

  return (
    <>
      <div className="auth auth-signup">
        <div className="signup">
          <div className="signup-info-div">
            <div className="signup-info-div-x-space"></div>
            <div className="signup-info-main-div">
              <div className="signup-info-main-top-space"></div>
              <span className="signup-title auth-title">
                <strong>Sign up</strong>
              </span>
              {signupStep === 0 ? (
                <>
                  <span className="signup-text auth-text pb-5">
                    Access everything we have to offer!
                  </span>
                  <SignupForm
                    onFinish={(values) => onFinish(values, handleSignupSuccess)}
                    onFinishFailed={onFinishFailed}
                    isLoading={isLoading}
                    onRecaptchaChange={onRecaptchaChange}
                    isRecaptchaVerified={isRecaptchaVerified}
                  />
                </>
              ) : (
                <VerifyEmailForm
                  otp={otp}
                  setOtp={setOtp}
                  verifyOTP={verifyOTP}
                  isLoading={isLoading}
                />
              )}
            </div>
            <div className="signup-info-div-x-space"></div>
          </div>
          <div className="signup-mark-div">
            <Mark />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Signup;
